<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Tooltips">
                    <template v-slot:description>
                        <div>import { HbTooltip } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Default Tooltip Hover Example + Code" class="mt-4 mb-6">
            <div class="pt-4 pb-6 px-6">
                <hb-tooltip>
                    <template v-slot:header>
                        Tooltip Header
                    </template>
                    <template v-slot:body>
                        Tooltip body
                    </template>
                </hb-tooltip>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-tooltip>
    &lt;template v-slot:header>
        Tooltip Header
    &lt;/template>
    &lt;template v-slot:body>
        Tooltip body
    &lt;/template>
&lt;/hb-tooltip>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Custom Activator Hover Example + Code" class="mt-4 mb-6">
            <div class="pt-4 pb-6 px-6">
                <hb-tooltip>
                    <template v-slot:item>
                        <hb-btn>Any Kind of Activator Can Go Here</hb-btn>
                    </template>
                    <template v-slot:header>
                        Tooltip Header
                    </template>
                    <template v-slot:body>
                        Tooltip body
                    </template>
                </hb-tooltip>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-tooltip>
    &lt;template v-slot:item>
        &lt;hb-btn>Any Kind of Activator Can Go Here&lt;/hb-btn>
    &lt;/template>
    &lt;template v-slot:header>
        Tooltip Header
    &lt;/template>
    &lt;template v-slot:body>
        Tooltip body
    &lt;/template>
&lt;/hb-tooltip>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Dashed Custom Activator Hover Example + Code" class="mt-4 mb-6">
            <div class="pt-4 pb-6 px-6">
                <hb-tooltip dashed>
                    <template v-slot:item>
                        Dashed Item
                    </template>
                    <template v-slot:header>
                        Tooltip Header
                    </template>
                    <template v-slot:body>
                        Tooltip body
                    </template>
                </hb-tooltip>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-tooltip dashed>
    &lt;template v-slot:item>
        Dashed Item
    &lt;/template>
    &lt;template v-slot:header>
        Tooltip Header
    &lt;/template>
    &lt;template v-slot:body>
        Tooltip body
    &lt;/template>
&lt;/hb-tooltip>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>
        
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemTooltips",
        data: function() {
            return {
                propHeaders: [
                    { text: 'Name', value: 'name', width: '150' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'disabled', type: 'boolean', default: 'false', description: 'If set to true, turns off the tooltip.' },
                    { name: 'dashed', type: 'boolean', default: 'false', description: 'If set to true, adds a dashed underline to the v-slot:item activator text.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'item', description: 'Sets a custom activator for where the tooltip will show when hovered. Default activator is the "mdi-information" icon and does not need this slot to be set in order to work.' },
                    { name: 'header', description: 'Sets the tooltip header text.' },
                    { name: 'body', description: 'Sets the tooltip body text.' },
                ],
            };
        },
    }
</script>

<style scoped>

</style>